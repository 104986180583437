import dayjs from "dayjs"
import { formatDate } from "./formatoFecha";
const userToken = localStorage.getItem("user-token");
export  function  returnJsonCrmOportunidades ( fecha, id,  comentario, vin, idClienteCrm){
    const now = dayjs().add(3, 'hour');
    const formattedDate = now.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    const json  = {
        "fecha":formattedDate,
        "tipo":10,
        "taller_id":8,
        "origen_id":26,
        "usuario_id":125,
        "comentario":comentario,
        "unidad_id":vin,
        "account_id":idClienteCrm
        }
    
    const options = {
            method: 'POST',
            body: JSON.stringify(json),
            headers: {"Content-type": "application/json; charset=UTF-8",
            "iron-token": userToken},
            redirect: 'follow'
    };
    return options
} 

export function returnJsonCrmTurnos (row, idOportunidad){
    const tipoServicio =
    row.tipoServicioReparacion === "1"
      ? "Reparación"
      : row.tipoServicioServicio === "1"
        ? "Servicio"
        : row.tipoServicioGarantia === "1"
          ? "Garantía"
          : row.tipoServicioOtros;
    const json = {
        
        //"comentario":`<ul><li>Id servicio app: ${id}</li><li>Cliente: ${cliente}</li><li>Fecha: ${fecha}</li><li>Contacto:${contacto}</li><li>Direccion: ${direccion} ${localidad}</li><li>Tecnico: ${tecnico}</li></ul><li><a target=’_blank’ href='https://app-service-iron-group.com.ar/Servicios/pdf/${id}'>Link PDF</a></li><li><a target=’_blank’ href='https://app-service-iron-group.com.ar/servicios/imagenes/${id}'>Link Imagenes</a></li></ul>`,
        "comentario": `<h3>Información del servicio</h3><p>Id service app: ${row.id}</p><p>Fecha: ${formatDate (row.fecha)} </p>
        <p><a target=’_blank’ href='https://app-service-iron-group.com.ar/Servicios/pdf/${row.id}'>Link PDF</a></p>
        <p><a target=’_blank’ href='https://app-service-iron-group.com.ar/servicios/imagenes/${row.id}'>Link Imagenes</a></p><h3>Información del cliente</h3>
        <p>Cliente: ${row.cliente}</p><p>Contacto: ${row.contacto}</p><p>DNI: ${row.dni}</p><h3>Información de la máquina</h3><p>Modelo: ${row.modelo}</p>
        <p>Chasis: ${row.chasis}</p><p>Motor: ${row.motor}</p><p>Tipo servicio: ${tipoServicio}</p><h3>Informe del técnico</h3>
        <p>Falla: ${row.descripcionFalla}</p>
        <p>Técnico: ${row.tecnico}</p><p>KM Recorridos: ${row.kmRecorridos}</p><p>Hora inicio: ${formatDate(row.horaInicio)}</p><p>Hora Fin: ${formatDate(row.horaFin)}</p>`,
        "fecha":dayjs(),
        "taller_id":8,
        "user_id":125,
        "key": idOportunidad
        }
    
        const options = {
            method: 'POST',
            body: JSON.stringify(json),
            headers: {"Content-type": "application/json; charset=UTF-8",
            "iron-token": userToken},
            redirect: 'follow'
    };
    return options
        
}
export function returnJsonCrmMaquinas (chasis){
    const json ={
        "vin_vis" : chasis
      }
    
        const options = {
            method: 'POST',
            body: JSON.stringify(json),
            headers: {"Content-type": "application/json; charset=UTF-8",
            "iron-token": userToken},
            redirect: 'follow'
    };
    return options
        
}
export function returnJsonCrmClintes (){

        const options = {
            method: 'GET',
            headers: {"Content-type": "application/json; charset=UTF-8",
            "iron-token": userToken},
            redirect: 'follow'
    };
    return options
        
}
export function returnJsonCrmFlag (){
  
        const options = {
            method: 'POST',
            headers: {"Content-type": "application/json; charset=UTF-8",
            "iron-token": userToken},
            redirect: 'follow'
    };
    return options
        
}
export function obtenerHora(fechaParam) {
    var fecha = new Date(fechaParam);

    var horas = fecha.getHours();
    var minutos = fecha.getMinutes();
 

    // Asegurarse de que las horas, minutos y segundos sean siempre dos dígitos
    horas = horas < 10 ? '0' + horas : horas;
    minutos = minutos < 10 ? '0' + minutos : minutos;
  

    return horas + ':' + minutos 
}