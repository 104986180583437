export const colums =[
    {
      accessorKey: "id",
      header: "ID",
    },
    {
      accessorKey: "flagCrm",
      header: "Enviado CRM",
    },

    {
      accessorKey: "cliente",
      header: "Cliente",
    },
    {
      accessorKey: "fecha",
      header: "Fecha",
    },
    {
      accessorKey: "contacto",
      header: "Contacto",
    },
    {
      accessorKey: "direccion",
      header: "Dirección",
    },
    {
      accessorKey: "localidad",
      header: "Localidad",
    },
    {
      accessorKey: "tecnico",
      header: "Técnico",
    },
   
  ]