import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Box, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import api from '../utils/api';

export default function ViewImages({servicios}) {
    const urlImage = api.urlImagenes
    const styleBox = {
      "& .MuiTextField-root": { m: 1 },
      p: 4,
      marginBottom: "20px",
      marginLeft: "5px",
      marginRight: "5px",
      alignItems: "center",
      borderRadius: 5,
      boxShadow: 5,
    };
    const isLargeScreen = useMediaQuery('(min-width: 600px)');
    const columns = isLargeScreen ? 6 : 1;
    return (
      <Box sx={styleBox}>
      {servicios && servicios.length > 0 ? (
        <ImageList sx={{ width: "100%", height: 450 }} cols={columns} rowHeight={164} gap={15}>
          {servicios.map((item) => (
            <ImageListItem key={item.id}>
              <a href={`${urlImage}${item.filename}`} target="_blank" rel="noopener noreferrer">
                <img
                  src={`${urlImage}${item.filename}`}
                  srcSet={`${urlImage}${item.filename}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.filename}
                  loading="lazy"
                  width={200}
                  height={200}
                />
              </a>
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <Typography display={"flex"} justifyContent={"center"}>No hay imágenes</Typography>
      )}
    </Box>
    );
  }