import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { CssBaseline } from "@mui/material";
import App from "./App";
import Auth from "./components/Auth/Auth";
import Login from "./pages/Login";
import ProtectedRoute from "./components/utils/ProtectedRoute";
import Home from "./pages/Home";
import Servicios from "./pages/Servicios";
import NuevoServicio from "./pages/NuevoServicio";
import ServiciosPendientes from "./pages/ServiciosPendientes";
import reportWebVitals from "./reportWebVitals";
import * as ServiceWorkerRegistration from "./serviceWorkerRegistration";
import "./index.css";
import ServiciosImagenes from "./pages/ServiciosImagenes";
import VisorPdf from "./pages/VisorPdf";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <CssBaseline />
    <BrowserRouter basename={"/"}>
      <Routes>
        <Route path="/Auth" element={<Auth />}>
          <Route path="Login" element={<Login />} />
        </Route>
        <Route path="/" element={<App />}>
          <Route path="" element={<Home />} />
          <Route path="Servicios" element={<Servicios />} />
          <Route path="NuevoServicio" element={<NuevoServicio />} />
          <Route path="servicios/imagenes/:idservicio" element={<ServiciosImagenes />} />
          <Route path="servicios/pdf/:idservicio" element={<VisorPdf />} />
          <Route
            path="Servicios-pendientes"
            element={<ServiciosPendientes />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </StrictMode>
);
ServiceWorkerRegistration.register();
reportWebVitals();
