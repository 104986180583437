import {
  AppBar,
  Button,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import NavListDrawer from "./NavListDrawer";
import { useState } from "react";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
//import Logo from '../../Img/IronLogo2.png'
import swal from "sweetalert";
import Logo from "../../Img/IRON_Logo blanco sin fondo.svg";

import MenuIcon from "@mui/icons-material/Menu";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import { Box } from "@mui/system";

import { Link, NavLink } from "react-router-dom";

import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import useOnlineStatus from "../../Hooks/UseOnlineStatus";

export default function Navbar({ navegationLinks, children }) {
  const [open, setOpen] = useState(false);

  const isOnline = useOnlineStatus();
  const navigate = useNavigate();

  const logout = () => {
    swal({
      title: "Deseas cerrar sesion?",
      text: "Solo podras volver a ingresar si tienes conexion a internet",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmacion) => {
      if (confirmacion) {
        localStorage.removeItem("usuario");
        localStorage.removeItem("roles");
        localStorage.removeItem("user-token");
        localStorage.removeItem("firma");
        localStorage.removeItem("nombre-Completo");
        navigate("/auth/login");
      } else {
      }
    });
  };

  return (
    <>
      <AppBar position="static" style={{backgroundColor:"#ED9519"}}>
        <Toolbar>
          <IconButton
            color="inherit"
            size="large"
            onClick={() => setOpen(true)}
            /*sx={{ display: { xs: "flex", sm: "none" } }}*/
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            <img
              src={Logo}
              alt="Logo"
              className="image"
              height={60}
              width={150}
            />
          </Box>
          <Typography style={{ marginRight: "10px"}}>Version 1.0.3 </Typography>

          {isOnline ? <WifiIcon /> : <WifiOffIcon />}

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button color="inherit" onClick={() => logout()}>
              <ExitToAppIcon />
              Cerrar Sesión
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Container sx={{ p: 0,}} disableGutters={true}>
        {children}
      </Container>
      <Drawer
        open={open}
        anchor="left"
        onClose={() => setOpen(false)}
        /*   sx={{ display: { xs: "flex", sm: "none" } }}*/
      >
        <NavListDrawer
          navegationLinks={navegationLinks}
          component={Link}
          setOpen={setOpen}
        />
      </Drawer>
    </>
  );
}
