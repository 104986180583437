import {
  Typography,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Button,
  Box,
  TextField,
  Grid,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import "../../pages/stylefirma.css";
import api from "../utils/api";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateField } from "@mui/x-date-pickers/DateField";
import SendIcon from "@mui/icons-material/Send";
import swal from "sweetalert";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/es";
import Loading from "../Loading/Loading";
import FileUpload from "../FileUploader/FileUploader";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("es");

export default function EditarServicio({
  rowdata,
  setEditingDataservicios,
  ListarServicios,
  setIsModalOpen,
  edit
}) {
  const userToken = localStorage.getItem("user-token");
  console.log(rowdata);
  const [canvas, setCanvas] = useState(rowdata.firmaCliente);
  const [cliente, setCliente] = React.useState(rowdata.cliente);
  const [contacto, setContacto] = React.useState(rowdata.contacto);
  const [direccion, setDireccion] = React.useState(rowdata.direccion);
  const [localidad, setLocalidad] = React.useState(rowdata.localidad);
  const [modelo, setModelo] = React.useState(rowdata.modelo);
  const [chasis, setChasis] = React.useState(rowdata.chasis);
  const [motor, setMotor] = React.useState(rowdata.motor);
  const [horometro, setHorometro] = React.useState(rowdata.horometro);
  const [hodometro, setHodometro] = React.useState(rowdata.hodometro);
  const [provincia, setProvincia] = React.useState(rowdata.provincia);
  const [dni, setDni] = React.useState(rowdata.dni);
  //const [telefono, setTelefono] = React.useState(rowdata.telefono);
  const [firmaTecnico, setFirmaTecnico] = React.useState(rowdata.firmaTecnico);
  //const [mail, setMail] = React.useState(rowdata.mail);
  const [flagReparacion, setFlagReparacion] = React.useState(
    rowdata.tipoServicioReparacion === "1" ? true : false
  );
  const [flagServicio, setFlagRServicio] = React.useState(
    rowdata.tipoServicioServicio === "1" ? true : false
  );
  const [flagGarantia, setFlagGarantia] = React.useState(
    rowdata.tipoServicioGarantia === "1" ? true : false
  );
  const [otros, setOtros] = React.useState(rowdata.tipoServicioOtros);
  const [falla, setFalla] = React.useState(rowdata.descripcionFalla);
  const [servicioRealizado, setServicioRealizado] = React.useState(
    rowdata.servicio
  );
  const [tecnico, setTecnico] = React.useState(rowdata.tecnico);
  const [km, setKm] = React.useState(rowdata.kmRecorridos);
  const [aclaracionFirma, setAclaracionFirma] = React.useState(
    rowdata.aclaracionCliente
  );
  const [horaFin, setHoraFin] = React.useState(dayjs(rowdata.horaFin));
  const [horaInicio, setHoraInicio] = React.useState(dayjs(rowdata.horaInicio));
  const UrlServicios = api.urlAPI + "servicios";
  const [loading, setLoading] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [fecha, setFecha] = React.useState(
    dayjs(rowdata.fecha).tz(dayjs.tz.guess())
  );
  const [titulo, setTitulo] = useState(edit ? `Modificar servicio: ${rowdata.id}` : `Visualizar servicio: ${rowdata.id}`);

  useEffect(() => {
    // Verificar si los campos requeridos no están vacíos
    const areFieldsNotEmpty =
      cliente !== "" &&
      contacto !== "" &&
      direccion !== "" &&
      localidad !== "" &&
      modelo !== "" &&
      chasis !== "" &&
      motor !== "" &&
      falla !== "" &&
      servicioRealizado !== "" &&
      tecnico !== "" &&
      canvas !== "" &&
      aclaracionFirma !== "" &&
      km !== "";
    const atLeastOneFieldHasData =
      flagReparacion || flagServicio || flagGarantia || otros !== "";

    setIsButtonEnabled(areFieldsNotEmpty && atLeastOneFieldHasData);
  }, [
    cliente,
    contacto,
    direccion,
    localidad,
    modelo,
    chasis,
    motor,
    falla,
    servicioRealizado,
    tecnico,
    km,
    canvas,
    aclaracionFirma,
    flagReparacion,
    flagServicio,
    flagGarantia,
    otros,
  ]);

  const handleChangeFlagReparacion = (event) => {
    setFlagReparacion(event.target.checked);
  };

  const handleChangeFlagServicio = (event) => {
    setFlagRServicio(event.target.checked);
  };

  const handleChangeFlagGarantia = (event) => {
    setFlagGarantia(event.target.checked);
  };

  //carga de imagenes
  const [images, setImages] = useState([]);

  const onImageChange = (event) => {
    if (event.target.files) {
      const fileList = Array.from(event.target.files);
      const imageUrls = fileList.map((file, index) => {
        const uniqueId = `${Date.now()}_${Math.floor(Math.random() * 10000)}`;
        const fileName = `imagen_${uniqueId}_${index + 1}.jpg`;
        const imageUrl = URL.createObjectURL(file);
        return { file, fileName, imageUrl };
      });
      setImages(imageUrls);
    }
  };

  const onDeleteAllImages = () => {
    setImages([]);
  };

  const onSaveImages = () => {
    images.forEach((image) => {
      const { file, fileName } = image;
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = fileName;
      link.click();
    });
  };

  //fin carga imagenes

  //Modificar datos
  const editarRegistro = async () => {
    const id = rowdata.id;

    swal({
      title: "¿Estás seguro?",
      text: `Esta acción modificará el registro ${rowdata.id} permanentemente.`,
      icon: "warning",
      buttons: ["Cancelar", "Guardar"],
      dangerMode: true,
    }).then(async (confirm) => {
      if (confirm) {
        const newData = {
          cliente: cliente,
          fecha: fecha,
          contacto: contacto,
          direccion: direccion,
          localidad: localidad,
          modelo: modelo,
          chasis: chasis,
          motor: motor,
          hodometro: hodometro,
          horometro: horometro,
          tipoServicioReparacion: flagReparacion,
          tipoServicioServicio: flagServicio,
          tipoServicioGarantia: flagGarantia,
          tipoServicioOtros: otros,
          descripcionFalla: falla,
          servicio: servicioRealizado,
          kmRecorridos: km,
          horaInicio: horaInicio,
          horaFin: horaFin,
          tecnico: tecnico,
          firmaTecnico: firmaTecnico,
          firmaCliente: canvas,
          aclaracionCliente: aclaracionFirma,
          repuestosPorServicios: handleSubmit(),
          provincia: provincia,
          dni: dni,
          //mail: mail
        };
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "iron-token": userToken,
          },
          body: JSON.stringify(newData),
        };

        try {
          setLoading(true);
          const response = await fetch(`${UrlServicios}/${id}`, requestOptions);
          const data = await response.json();
          setLoading(false);

          if (response.ok) {
            swal(
              "¡Modificación exitosa!",
              `El registro ${rowdata.id} ha sido modificado correctamente.`,
              "success"
            );
            setIsModalOpen(false);
            setEditingDataservicios(false);
            ListarServicios();
          } else {
            swal(
              "Error",
              `Hubo un error al modificar el registro ${rowdata.id}.`,
              "error"
            );
          }
        } catch (error) {
          // Handle any errors that occur during the request
          swal("Error", "Hubo un error al modificar el registro.", "error");
        }
      }
    });
  };

  const [lines, setLines] = useState(rowdata.repuestosPorServicios);

  const addLine = () => {
    const maxId = lines.reduce(
      (max, line) => (line.id > max ? line.id : max),
      0
    );
    const newLine = { id: maxId + 1 };
    setLines([...lines, newLine]);
  };

  const deleteLine = () => {
    if (lines.length > 1) {
      const updatedLines = [...lines];
      updatedLines.pop();
      setLines(updatedLines);
    }
  };
  const handleInputChange = (event, lineId, fieldId) => {
    const updatedLines = lines.map((line) => {
      if (line.id === lineId) {
        return {
          ...line,
          [fieldId]: event.target.value,
        };
      }
      return line;
    });
    setLines(updatedLines);
  };

  const handleSubmit = () => {
    const formData = [];
    lines.forEach((line) => {
      const data = {};
      Object.keys(line).forEach((key) => {
        if (key !== "id") {
          data[key] = line[key];
          console.log(data[key]);
        }
      });
      data.idServicio = rowdata.id;
      formData.push(data);
    });
    console.log(formData);
    return formData;
  };

  const styleBox = {
    "& .MuiTextField-root": { m: 1 },
    p: 4,
    marginBottom: "20px",
    marginLeft: "5px",
    marginRight: "5px",
    alignItems: "center",
    borderRadius: 5,
    boxShadow: 5,
  };

  return (
    <>
      <Box sx={styleBox}>
        <Typography
          variant="h6"
          color="white"
          align="center"
          pb={1}
          marginBottom={"20px"}
          style={{
            backgroundColor: "#161616",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          {titulo}
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="cliente"
                label="Cliente *"
                variant="outlined"
                fullWidth
                value={cliente}
                onChange={(e) => setCliente(e.target.value)}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                  label="Fecha *"
                  value={fecha}
                  onChange={(newValue) => setFecha(newValue)}
                  fullWidth
                  disabled
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="contacto"
                label="Contacto *"
                variant="outlined"
                fullWidth
                value={contacto}
                onChange={(e) => setContacto(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="direccion"
                label="Dirección *"
                variant="outlined"
                fullWidth
                value={direccion}
                onChange={(e) => setDireccion(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="localidad"
                label="Localidad *"
                variant="outlined"
                fullWidth
                value={localidad}
                onChange={(e) => setLocalidad(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="Provincia"
                label="Provincia"
                variant="outlined"
                fullWidth
                value={provincia}
                onChange={(e) => setProvincia(e.target.value)}
                required
              />
            </Grid>
            {/*   <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="telefono"
                label="Telefono"
                variant="outlined"
                fullWidth
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
                required

              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="mail"
                label="Mail"
                variant="outlined"
                fullWidth
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                required

              />
            </Grid> */}

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="modelo"
                label="Modelo*"
                variant="outlined"
                fullWidth
                value={modelo}
                onChange={(e) => setModelo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="chasis"
                label="N° Chásis *"
                variant="outlined"
                fullWidth
                value={chasis}
                onChange={(e) => setChasis(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="motor"
                label="N° Motor *"
                variant="outlined"
                fullWidth
                value={motor}
                onChange={(e) => setMotor(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="horometro"
                label="Horometro *"
                variant="outlined"
                fullWidth
                value={horometro}
                onChange={(e) => setHorometro(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="hodometro"
                label="Hodometro *"
                variant="outlined"
                fullWidth
                value={hodometro}
                onChange={(e) => setHodometro(e.target.value)}
              />
            </Grid>
          </Grid>
        </Box>

        <Box alignItems="center" marginTop="20px">
          <FormLabel sx={{ marginLeft: "15px" }}>Tipo de servicio * </FormLabel>
          <FormControlLabel
            sx={{ marginLeft: "15px" }}
            control={
              <Checkbox
                checked={flagReparacion}
                onChange={handleChangeFlagReparacion}
                disabled={otros !== "" || flagServicio || flagGarantia}
              />
            }
            label="Reparacion"
          />
          <FormControlLabel
            sx={{ marginLeft: "15px" }}
            control={
              <Checkbox
                checked={flagServicio}
                onChange={handleChangeFlagServicio}
                disabled={otros !== "" || flagReparacion || flagGarantia}
              />
            }
            label="Servicio"
          />
          <FormControlLabel
            sx={{ marginLeft: "15px" }}
            control={
              <Checkbox
                checked={flagGarantia}
                onChange={handleChangeFlagGarantia}
                disabled={otros !== "" || flagServicio || flagReparacion}
              />
            }
            label="Garantia"
          />
          <TextField
            id="otros"
            label="Otros"
            variant="outlined"
            fullWidth
            value={otros}
            onChange={(e) => setOtros(e.target.value)}
            disabled={flagReparacion || flagServicio || flagGarantia}
          />
        </Box>
      </Box>
      <Box sx={styleBox}>
        <Typography
          variant="h6"
          color="white"
          align="center"
          pb={1}
          marginBottom={"20px"}
          style={{
            backgroundColor: "#ED9519",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          Descripción de la falla y causa
        </Typography>
        <TextField
          id="descripcionfalla"
          label="Descripción de la falla y causa *"
          multiline
          fullWidth
          minRows={3}
          maxRows={10}
          value={falla}
          onChange={(e) => setFalla(e.target.value)}
        />
        <Box>
          <TextField
            type="file"
            fullWidth
            onChange={onImageChange}
            inputProps={{
              multiple: true,
            }}
          />
        </Box>
        <Typography
          variant="h6"
          color="white"
          align="center"
          pb={1}
          marginTop={"40px"}
          marginBottom={"20px"}
          style={{
            backgroundColor: "#161616",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          Servicio realizado
        </Typography>
        <TextField
          id="serviciorealizado"
          label="Servicio realizado *"
          multiline
          fullWidth
          minRows={3}
          maxRows={10}
          marginBottom={"60px"}
          value={servicioRealizado}
          onChange={(e) => setServicioRealizado(e.target.value)}
        />
      </Box>

      <Box sx={styleBox}>
        <Typography
          variant="h6"
          color="white"
          align="center"
          pb={1}
          marginBottom={"20px"}
          style={{
            backgroundColor: "#ED9519",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          Repuestos utilizados
        </Typography>
        <Box>
          <Box marginBottom="50px">
            <Grid container spacing={2}>
              {lines.map((line) => (
                <React.Fragment key={line.id}>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                      id={`cantidad-${line.id}`}
                      label="Cantidad"
                      variant="outlined"
                      type="number"
                      fullWidth
                      value={line.cantidad || ""}
                      onChange={(event) =>
                        handleInputChange(event, line.id, "cantidad")
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                      id={`codigo-${line.id}`}
                      label="Codigo"
                      variant="outlined"
                      fullWidth
                      value={line.codigo || ""}
                      onChange={(event) =>
                        handleInputChange(event, line.id, "codigo")
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                      id={`descripcion-${line.id}`}
                      label="Descripcion"
                      variant="outlined"
                      fullWidth
                      value={line.descripcion || ""}
                      onChange={(event) =>
                        handleInputChange(event, line.id, "descripcion")
                      }
                    />
                  </Grid>
                  { /*
                     <Grid item xs={12} sm={6} md={4} lg={3}>
                     <TextField
                       id={`precioUnitario-${line.id}`}
                       label="Precio Unitario"
                       variant="outlined"
                       fullWidth
                       type="number"
                       value={line.precioUnitario || ""}
                       onChange={(event) =>
                         handleInputChange(event, line.id, "precioUnitario")
                       }   
                     />
                     
                   </Grid>
                   */
                  }
                </React.Fragment>
              ))}
            </Grid>
          </Box>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Button
                variant="contained"
                style={{ backgroundColor: '#161616', color: '#FFFFFF', marginTop: "20px" }}
                onClick={addLine}
                fullWidth
              >
                Agregar línea
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Button
                variant="contained"
                style={{ backgroundColor: '#ED9519', color: '#FFFFFF', marginTop: "20px" }}
                onClick={deleteLine}
                fullWidth
              >
                Eliminar línea
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box sx={styleBox}>
        <Typography
          variant="h6"
          color="white"
          align="center"
          pb={1}
          marginBottom={"20px"}
          style={{
            backgroundColor: "#161616",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          Datos del tecnico
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="TecnicoInterviniente"
                label="Tecnico Interviniente *"
                variant="outlined"
                fullWidth
                value={tecnico}
                onChange={(e) => setTecnico(e.target.value)}
                InputProps={{
                  readOnly: true,
                }}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="kmRecorrido"
                label="Km. Recorrido *"
                variant="outlined"
                type="number"
                fullWidth
                value={km}
                onChange={(e) => setKm(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Hora inicio de la tarea"
                  value={horaInicio}
                  onChange={(newValue) => setHoraInicio(newValue)}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Hora fin de la tarea"
                  value={horaFin}
                  onChange={(newValue) => setHoraFin(newValue)}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={styleBox}>
        <Typography
          variant="h6"
          color="white"
          align="center"
          pb={1}
          marginBottom={"20px"}
          style={{
            backgroundColor: "#ED9519",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          Manifiesta conforme con la reparacion efectuada
        </Typography>
        <FormLabel
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
            marginTop: "30PX",
          }}
        >
          Firma del cliente:{" "}
        </FormLabel>

        <Box className="box-signature">
          <img src={canvas} alt="Firma cliente"></img>
        </Box>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="Dni"
              label="DNI cliente"
              variant="outlined"
              marginTop="20px"
              fullWidth
              value={dni}
              onChange={(e) => setDni(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="aclaracion"
              label="Aclaracion firma"
              variant="outlined"
              marginTop="20px"
              fullWidth
              value={aclaracionFirma}
              onChange={(e) => setAclaracionFirma(e.target.value)}
              required
            />
          </Grid>
        </Grid>
      </Box>
      {loading && <Loading />}
      {edit && (
        <Box sx={styleBox}>
          <Button
            variant="contained"
            fullWidth
            endIcon={<SendIcon />}
            style={{ backgroundColor: '#161616', color: '#FFFFFF', marginTop: "20px" }}
            disabled={!isButtonEnabled}
            onClick={() => {
              editarRegistro();
            }}
          >
            Modificar Servicio
          </Button>
        </Box>
      )}
    </>
  );
}
