
export const styleBox = {
    "& .MuiTextField-root": { m: 1 },
    p: 4,
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "40px",
    marginTop: "20px",
    alignItems: "center",
    borderRadius: 5,
    boxShadow: 5,
  };