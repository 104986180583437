import React from 'react'
import { useParams } from "react-router";
import api from "../components/utils/api";
import { useState } from 'react';
import swal from 'sweetalert';
import { useEffect } from 'react';
import { formatDate } from '../components/utils/formatoFecha';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { stylesPdf } from "../components/utils/formatoPdf";
import './visorPdf.css';
import { obtenerHora } from '../components/utils/Funciones';

const VisorPdf = () => {
    const { idservicio } = useParams();
    const UrlServicios = api.urlAPI + "servicios/"+ idservicio ;
    const [loading, setLoading] = useState(false);
    const [servicio, setServicio] = useState([]);
    const userToken = localStorage.getItem("user-token");
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const ListarServicioPorID = async () => {
        
        try {
          const headers = { "iron-token": userToken };
          setLoading(true);
          let res = await fetch(UrlServicios, { headers });
          setLoading(false);
          if (res.ok) {
            let data = await res.json();
            console.log(data.data)
            generarPDF(data.data)
            setServicio(data.data);
          } else {
            setLoading(false);
            throw new Error(
              "No fue posible obtener los registros, vuelve a intentar más tarde."
            );
          }
        } catch (error) {
          setLoading(false);
          swal("Error", error.message, "error");
        }
      };
      useEffect(() => {
        ListarServicioPorID();
      
      }, []);
  

       //Genera pdf
  const generarPDF = (rowData) => {
    const imageUrl = require("../Img/IronLogo2.png");
    const colorPdf = "#D8D8D8";
    const tipoServicio =
      rowData.tipoServicioReparacion === "1"
        ? "Reparación"
        : rowData.tipoServicioServicio === "1"
          ? "Servicio"
          : rowData.tipoServicioGarantia === "1"
            ? "Garantía"
            : rowData.tipoServicioOtros;
    var tableData = [
      [
        { text: "Cantidad", style: "tableHeader", fillColor: colorPdf },
        { text: "Código", style: "tableHeader", fillColor: colorPdf },
        { text: "Descripción", style: "tableHeader", fillColor: colorPdf },
      ],
    ];
    console.log(rowData.repuestosPorServicios)
    rowData.repuestosPorServicios.forEach(function (repuesto) {
      var row = [
        `${repuesto.cantidad}`,
        `${repuesto.codigo}`,
        `${repuesto.descripcion}`,
      ];
      tableData.push(row);
    });

    //Contenido del pdf
    const content = [
      {
        alignment: "justify",
        columns: [
          {
            text: "Solicitud de servicio técnico",
            style: "subheader2",
            alignment: "left",
          },
          {
            text: `Fecha: ${formatDate(rowData.fecha)}`,
            style: "fecha",
            verticalAlign: "top",
            alignment: "right",
          },
          {
            text: `Formulario: ${rowData.id}`,
            style: "fecha",
            verticalAlign: "top",
            alignment: "right",
          },
        ],
      },
      { text: "Información del cliente", style: "subheader" },
      {
        style: "tableExample",
        table: {
          widths: [120, 120, 120, 120],
          headerRows: 1,
          body: [
            [
              { text: "Cliente", style: "tableHeader", fillColor: colorPdf },
              { text: "Contacto", style: "tableHeader", fillColor: colorPdf },
              { text: "Dirección", style: "tableHeader", fillColor: colorPdf },
              { text: "Localidad", style: "tableHeader", fillColor: colorPdf },
            ],

            [
              `${rowData.cliente}`,
              `${rowData.contacto}`,
              `${rowData.direccion}`,
              `${rowData.localidad}`,
            ],
          ],
        },
      },
      { text: "Información de la máquina", style: "subheader" },

      {
        style: "tableExample",
        table: {
          headerRows: 1,
          widths: [120, 120, 120, 120],
          body: [
            [
              { text: "Modelo", style: "tableHeader", fillColor: colorPdf },
              { text: "Chasis", style: "tableHeader", fillColor: colorPdf },
              { text: "Motor", style: "tableHeader", fillColor: colorPdf },
              {
                text: "Tipo servicio",
                style: "tableHeader",
                fillColor: colorPdf,
              },
            ],

            [
              `${rowData.modelo}`,
              `${rowData.chasis}`,
              `${rowData.motor}`,
              tipoServicio,
            ],
          ],
        },
      },
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          widths: [249, 249],
          body: [
            [
              {
                text: "Horometro",
                style: "tableHeader",
                fillColor: colorPdf,
              },
              {
                text: "Hodometro",
                style: "tableHeader",
                fillColor: colorPdf,
              },
            ],

            [`${rowData.horometro}`, `${rowData.hodometro}`],
          ],
        },
      },
      { text: "Informe del técnico", style: "subheader" },
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          widths: [249, 249],
          body: [
            [
              {
                text: "Descripción  de falla",
                style: "tableHeader",
                fillColor: colorPdf,
              },
              {
                text: "Servicio realizado",
                style: "tableHeader",
                fillColor: colorPdf,
              },
            ],

            [`${rowData.descripcionFalla}`, `${rowData.servicio}`],
          ],
        },
      },
      { text: "Repuestos utilizados", style: "subheader" },
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          widths: [163, 163, 163],
          body: tableData,
        },
      },
      { text: "Información del Técnico", style: "subheader" },
      {
        style: "tableExample",
        table: {
          widths: [120, 120, 120, 120],
          headerRows: 1,
          body: [
            [
              { text: "Técnico", style: "tableHeader", fillColor: colorPdf },
              {
                text: "km Recorridos",
                style: "tableHeader",
                fillColor: colorPdf,
              },
              {
                text: "Hora inicio",
                style: "tableHeader",
                fillColor: colorPdf,
              },
              { text: "Hora fin", style: "tableHeader", fillColor: colorPdf },
            ],
            [
              `${rowData.tecnico}`,
              `${rowData.kmRecorridos}`,
              `${obtenerHora(rowData.horaInicio)}`,
              `${obtenerHora(rowData.horaFin)}`,
            ],
          ],
        },
      },
      {
        text: "Iron Group no responde por:",
        style: "subheader",
        headlineLevel: 1,
      },
      {
        style: "tableExample2",
        table: {
          widths: ["*"],
          body: [
            [
              "REPARACIONES HECHAS POR OTRAS QUE NO SEAN LAS FUENTES AUTORIZADAS DE REPARACIÓN IRON GROUP, ABUSO, ACCIDENTE, DESCUIDO Y/O ALMACENAMIENTO INAPROPIADO, USO DE LAS PIEZAS O ACCESORIO INSTALADOS QUE NO HAYAN SIDO FABRICADOS Y/O RECOMENDADOS POR IRON GROUP",
            ],
          ],
        },
      },

      {
        text: "Me manifiesto conforme con la reparación efectuada",
        style: "subheader",
        headlineLevel: 1,
      },
      {
        style: "tableExample",
        table: {
          widths: ["*", "*", "*", "*"],
          headerRows: 2,
          body: [
            [
              {
                text: "Firma cliente",
                style: "tableHeader",
                fillColor: colorPdf,
                headerRows: 1,
              },
              {
                text: "Aclaración - DNI",
                style: "tableHeader",
                fillColor: colorPdf,
                headerRows: 1,
              },
              {
                text: "Firma Técnico",
                style: "tableHeader",
                fillColor: colorPdf,
                headerRows: 1,
              },
              {
                text: "Aclaración de firma Técnico",
                style: "tableHeader",
                fillColor: colorPdf,
                headerRows: 1,
              },
            ],

            [
              {
                image: `${rowData.firmaCliente}`,
                width: 100,
                alignment: "center",
              },
              { text: ` ${rowData.aclaracionCliente} - ${rowData.dni}`, alignment: "center" },
              {
                image: `${rowData.firmaTecnico}`,
                width: 100,
                height: 50,
                alignment: "center",
              },
              { text: ` ${rowData.tecnico}`, alignment: "center" },
            ],
          ],
        },
      },
    ];
   


    const documentDefinition = {
      footer: function (currentPage, pageCount) {
        return currentPage.toString() + " de " + pageCount;
      },
      header: {
        margin: 16,
        columns: [
          { image: imageUrl, width: 180, style: "imagenLogo" },
          [
            {
              width: "auto",
              text: "   Dirección: km 26351, Colectora Este Panamericana 1611, B1611 Don Torcuato,\n Provincia de Buenos Aires  postventa@iron-group.com | www.iron-group.com",
              style: "direccion",
              alignment: "right",
            },
          ],
        ],
      },
      content: content,
      
      pageBreakBefore: function (currentNode, followingNodesOnPage) {
        return (
          currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0
        );
      },
      styles: stylesPdf,
    };
 
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.getDataUrl((dataUrl) => {
        const targetElement = document.querySelector('#iframeContainer');
        const iframe = document.createElement('iframe');
        iframe.src = dataUrl;
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        iframe.style.position = 'absolute';
        targetElement.appendChild(iframe);
       });
  };
  return (
    <div className='visor'>
        <div  id='iframeContainer'></div>
    </div>
    
  )
}

export default VisorPdf