import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Dialog,
} from "@mui/material";
import api from "../components/utils/api";
import { MaterialReactTable } from "material-react-table";
import IconButton from "@mui/material/IconButton";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import SendIcon from '@mui/icons-material/Send';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { colums } from "../components/utils/Columns";
import { formatDate } from "../components/utils/formatoFecha";
import { stylesPdf } from "../components/utils/formatoPdf";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import swal from "sweetalert";
import EditarServicio from "../components/EditarServicio/EditarServicio";
import Loading from "../components/Loading/Loading";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ImageIcon from "@mui/icons-material/Image";
import { useNavigate } from 'react-router-dom';
import { returnJsonCrmTurnos } from "../components/utils/Funciones";
import { returnJsonCrmOportunidades, returnJsonCrmMaquinas, returnJsonCrmFlag, returnJsonCrmClintes } from "../components/utils/Funciones";
import useFetch from '../Hooks/UseFetch'
import { obtenerHora } from "../components/utils/Funciones";


export default function Servicios() {

  const navigate = useNavigate();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const userToken = localStorage.getItem("user-token");
  const UrlServicios = api.urlAPI + "servicios";
  const Urlflag = api.urlAPI + `crm/servicios/`
  const UrlCrmOportunidades = api.crmOportunidades
  const UrlCrmClientes = api.crmClientes
  const UrlCrmTurnos = api.crmTurnos
  const UrlCrmMaquinas = api.crmMaquinas
  const [servicios, setServicios] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [mode, setMode] = useState(false);
  const [editingDataservicios, setEditingDataservicios] = useState({});
  const [loading, setLoading] = useState(false);
  const { fetchOportunidad, fetchTurnos, fetchMaquinas, fetchFlag, fetchCliente } = useFetch()

  const rol = localStorage.getItem("roles");
  const mostrarBotones = rol === "ROLE_ADMIN";

  const ListarServicios = async () => {
    try {
      const headers = { "iron-token": userToken };
      setLoading(true);
      let res = await fetch(UrlServicios, { headers });
      setLoading(false);
      if (res.ok) {
        let data = await res.json();
        setServicios(data);
      } else {
        setLoading(false);
        throw new Error(
          "No fue posible obtener los registros, vuelve a intentar más tarde."
        );
      }
    } catch (error) {
      setLoading(false);
      swal("Error", error.message, "error");
    }
  };

  // elimina registro

  const eliminarRegistro = async (id, row) => {
    // Mostrar una confirmación utilizando SweetAlert
    swal({
      title: "¿Estás seguro?",
      text: "Esta acción eliminará el registro permanentemente.",
      icon: "warning",
      buttons: ["Cancelar", "Eliminar"],
      dangerMode: true,
    }).then(async (confirm) => {
      if (confirm) {
        if (row.flagCrm === "1") {
          swal("Error", "No se pueden eliminar registros informados al Crm.", "error");
          return
        }
        const requestOptions = {
          method: "DELETE",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "iron-token": userToken,
          },
        };

        try {
          setLoading(true);
          const response = await fetch(`${UrlServicios}/${id}`, requestOptions);
          const data = await response.json();
          setLoading(false);
          if (response.ok) {
            swal(
              "¡Eliminado!",
              "El registro se eliminó correctamente.",
              "success"
            );
            ListarServicios();
          } else {
            setLoading(false);
            swal("Error", "Ocurrió un error al eliminar el registro.", "error");
          }
        } catch (error) {
          setLoading(false);
          swal("Error", "Ocurrió un error al eliminar el registro.", "error");
        }
      }
    });
  };

  const editarServicio = (id, row, edit) => {
    if (edit) {
      setEditingData(row);
      setMode(true);
    } else {
      setMode(false);
    }
    setIsModalOpen(true);
    setEditingDataservicios(row.original);
  };

  //Genera pdf
  const generarPDF = (rowData) => {
    const imageUrl = require("../Img/IronLogo2.png");
    const colorPdf = "#D8D8D8";
    const tipoServicio =
      rowData.tipoServicioReparacion === "1"
        ? "Reparación"
        : rowData.tipoServicioServicio === "1"
          ? "Servicio"
          : rowData.tipoServicioGarantia === "1"
            ? "Garantía"
            : rowData.tipoServicioOtros;
    var tableData = [
      [
        { text: "Cantidad", style: "tableHeader", fillColor: colorPdf },
        { text: "Código", style: "tableHeader", fillColor: colorPdf },
        { text: "Descripción", style: "tableHeader", fillColor: colorPdf },
      ],
    ];

    rowData.repuestosPorServicios.forEach(function (repuesto) {
      var row = [
        `${repuesto.cantidad}`,
        `${repuesto.codigo}`,
        `${repuesto.descripcion}`,
      ];
      tableData.push(row);
    });

    //Contenido del pdf
    const content = [
      {
        alignment: "justify",
        columns: [
          {
            text: "Solicitud de servicio técnico",
            style: "subheader2",
            alignment: "left",
          },
          {
            text: `Fecha: ${formatDate(rowData.fecha)}`,
            style: "fecha",
            verticalAlign: "top",
            alignment: "right",
          },
          {
            text: `Formulario: ${rowData.id}`,
            style: "fecha",
            verticalAlign: "top",
            alignment: "right",
          },
        ],
      },
      { text: "Información del cliente", style: "subheader" },
      {
        style: "tableExample",
        table: {
          widths: [120, 120, 120, 120],
          headerRows: 1,
          body: [
            [
              { text: "Cliente", style: "tableHeader", fillColor: colorPdf },
              { text: "Contacto", style: "tableHeader", fillColor: colorPdf },
              { text: "Dirección", style: "tableHeader", fillColor: colorPdf },
              { text: "Localidad", style: "tableHeader", fillColor: colorPdf },
            ],

            [
              `${rowData.cliente}`,
              `${rowData.contacto}`,
              `${rowData.direccion}`,
              `${rowData.localidad}`,
            ],
          ],
        },
      },
      { text: "Información de la máquina", style: "subheader" },

      {
        style: "tableExample",
        table: {
          headerRows: 1,
          widths: [120, 120, 120, 120],
          body: [
            [
              { text: "Modelo", style: "tableHeader", fillColor: colorPdf },
              { text: "Chasis", style: "tableHeader", fillColor: colorPdf },
              { text: "Motor", style: "tableHeader", fillColor: colorPdf },
              {
                text: "Tipo servicio",
                style: "tableHeader",
                fillColor: colorPdf,
              },
            ],

            [
              `${rowData.modelo}`,
              `${rowData.chasis}`,
              `${rowData.motor}`,
              tipoServicio,
            ],
          ],
        },
      },
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          widths: [249, 249],
          body: [
            [
              {
                text: "Horometro",
                style: "tableHeader",
                fillColor: colorPdf,
              },
              {
                text: "Hodometro",
                style: "tableHeader",
                fillColor: colorPdf,
              },
            ],

            [`${rowData.horometro}`, `${rowData.hodometro}`],
          ],
        },
      },
      { text: "Informe del técnico", style: "subheader" },
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          widths: [249, 249],
          body: [
            [
              {
                text: "Descripción  de falla",
                style: "tableHeader",
                fillColor: colorPdf,
              },
              {
                text: "Servicio realizado",
                style: "tableHeader",
                fillColor: colorPdf,
              },
            ],

            [`${rowData.descripcionFalla}`, `${rowData.servicio}`],
          ],
        },
      },
      { text: "Repuestos utilizados", style: "subheader" },
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          widths: [163, 163, 163],
          body: tableData,
        },
      },
      { text: "Información del Técnico", style: "subheader" },
      {
        style: "tableExample",
        table: {
          widths: [120, 120, 120, 120],
          headerRows: 1,
          body: [
            [
              { text: "Técnico", style: "tableHeader", fillColor: colorPdf },
              {
                text: "km Recorridos",
                style: "tableHeader",
                fillColor: colorPdf,
              },
              {
                text: "Hora inicio",
                style: "tableHeader",
                fillColor: colorPdf,
              },
              { text: "Hora fin", style: "tableHeader", fillColor: colorPdf },
            ],
            [
              `${rowData.tecnico}`,
              `${rowData.kmRecorridos}`,
              `${obtenerHora(rowData.horaInicio)}`,
              `${obtenerHora(rowData.horaFin)}`,
            ],
          ],
        },
      },
      {
        text: "Iron Group no responde por:",
        style: "subheader",
        headlineLevel: 1,
      },
      {
        style: "tableExample2",
        table: {
          widths: ["*"],
          body: [
            [
              "REPARACIONES HECHAS POR OTRAS QUE NO SEAN LAS FUENTES AUTORIZADAS DE REPARACIÓN IRON GROUP, ABUSO, ACCIDENTE, DESCUIDO Y/O ALMACENAMIENTO INAPROPIADO, USO DE LAS PIEZAS O ACCESORIO INSTALADOS QUE NO HAYAN SIDO FABRICADOS Y/O RECOMENDADOS POR IRON GROUP",
            ],
          ],
        },
      },

      {
        text: "Me manifiesto conforme con la reparación efectuada",
        style: "subheader",
        headlineLevel: 1,
      },
      {
        style: "tableExample",
        table: {
          widths: ["*", "*", "*", "*"],
          headerRows: 2,
          body: [
            [
              {
                text: "Firma cliente",
                style: "tableHeader",
                fillColor: colorPdf,
                headerRows: 1,
              },
              {
                text: "Aclaración - DNI",
                style: "tableHeader",
                fillColor: colorPdf,
                headerRows: 1,
              },
              {
                text: "Firma Técnico",
                style: "tableHeader",
                fillColor: colorPdf,
                headerRows: 1,
              },
              {
                text: "Aclaración de firma Técnico",
                style: "tableHeader",
                fillColor: colorPdf,
                headerRows: 1,
              },
            ],

            [
              {
                image: `${rowData.firmaCliente}`,
                width: 100,
                alignment: "center",
              },
              { text: ` ${rowData.aclaracionCliente} - ${rowData.dni}`, alignment: "center" },
              {
                image: `${rowData.firmaTecnico}`,
                width: 100,
                height: 50,
                alignment: "center",
              },
              { text: ` ${rowData.tecnico}`, alignment: "center" },
            ],
          ],
        },
      },
    ];

    const documentDefinition = {
      footer: function (currentPage, pageCount) {
        return currentPage.toString() + " de " + pageCount;
      },
      header: {
        margin: 16,
        columns: [
          { image: imageUrl, width: 180, style: "imagenLogo" },
          [
            {
              width: "auto",
              text: "   Dirección: km 26351, Colectora Este Panamericana 1611, B1611 Don Torcuato,\n Provincia de Buenos Aires  postventa@iron-group.com | www.iron-group.com",
              style: "direccion",
              alignment: "right",
            },
          ],
        ],
      },
      content: content,
      pageBreakBefore: function (currentNode, followingNodesOnPage) {
        return (
          currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0
        );
      },
      styles: stylesPdf,
    };

    //Verifico si es un movil o una pc
    function isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    }
    if (isMobileDevice()) {
      pdfMake.createPdf(documentDefinition).download();
    } else {
      pdfMake.createPdf(documentDefinition).open();
    }
  };

  const VerImagenes = (row) => {
    navigate(`/servicios/imagenes/${row.id}`);
  };

  const EnviarCrm = async (row) => {

    swal({
      title: '¿Estás seguro?',
      text: "Se enviará el registro, válida que los datos sean correctos",
      icon: 'warning',
      buttons: ["Cancelar", "Enviar"],
      dangerMode: true,
    }).then(async (confirm) => {
      if (confirm) {
        setLoading(true);
        if (row.flagCrm === "1") {
          swal("Error!", "El servicio ya fue informado al CRM", "error");
          setLoading(false);
          return
        }
        const optionMaquina = returnJsonCrmMaquinas(row.chasis)
        const { responseMaquina, errorMaquina } = await fetchMaquinas(UrlCrmMaquinas, optionMaquina);

        if (errorMaquina) {
          swal("Error!", "Ocurrió un error al obtener la maquina", "error");
          setLoading(false);
          return
        }

        if (responseMaquina.data.data[0].matches.length === 0) {
          swal("Oops!", "El chasis no existe", "error");
          setLoading(false);
          return
        }
        var vin = responseMaquina.data.data[0].matches[0].target_id;
        console.log(vin)
        const optionsCliente = returnJsonCrmClintes()
        const { responseCliente, errorCliente } = await fetchCliente(UrlCrmClientes + vin, optionsCliente);
        
        if(errorCliente){
          swal("Error!", "No fue posible obtener el numero de cliente", "error");
        }
      
        const account_id = (responseCliente.data?.data?.account_id)
        console.log()

        if (account_id === null) {
          swal("Error!", "El id de cliente es nulo", "error");
        }
        const options = returnJsonCrmOportunidades(row.fecha, row.id, row.servicio, vin, account_id)
        console.log(options)
        const { responseOportunidad, errorOportunidad } = await fetchOportunidad(UrlCrmOportunidades, options);

        if (errorOportunidad) {
          swal("Oops!", "Ocurrió un error al crear la oportunidad!", "error");
          setLoading(false);
          return
        }

        const oportunidadId = responseOportunidad.data.data.id

        const bodyTurno = returnJsonCrmTurnos(row, oportunidadId)

        const { responseTurno, errorTurno } = await fetchTurnos(UrlCrmTurnos, bodyTurno);

        if (errorTurno) {
          swal("Oops!", "Ocurrió un error al crear el turno!", "error");
          setLoading(false);
          return
        }

        const flagBody = returnJsonCrmFlag()
        const { responseFlag, errorFlag } = await fetchFlag(Urlflag + row.id, flagBody);

        if (errorFlag) {
          swal("Oops!", "Se envio el servicio al crm pero no pudo ser actualizado en la aplicacion", "error");
          setLoading(false);
          return
        }
        setLoading(false);
        swal("Éxito!", "Servicio Enviado a CRM correctamente.", "success");

      }
    })

  }

  const columns = useMemo(() => colums, []);

  useEffect(() => {
    ListarServicios();
  }, []);

  return (
    <>
      <Box
        id="ok"
        sx={{
          "& .MuiTextField-root": { m: 1 },
          boxShadow: 5,
          p: 4,
          m: 2,
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          color="white"
          align="center"
          pb={1}
          marginBottom={"20px"}
          style={{
            backgroundColor: "#161616",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          Servicios Realizados
        </Typography>
        {loading && <Loading />}
        <MaterialReactTable
          columns={columns}
          data={servicios}
          enableRowActions
          renderRowActions={({ row }) => (
            <Box>
              {mostrarBotones && (
                <IconButton onClick={() => EnviarCrm(row.original)}>
                  <SendIcon />
                </IconButton>
              )}
              <IconButton onClick={() => generarPDF(row.original)}>
                <PictureAsPdfIcon />
              </IconButton>

              <IconButton onClick={() => VerImagenes(row.original)}>
                <ImageIcon />
              </IconButton>

              <IconButton
                onClick={() => editarServicio(row.original.id, row, false)}
              >
                <RemoveRedEyeIcon />
              </IconButton>
              {mostrarBotones && (
                <>
                  <IconButton
                    onClick={() => editarServicio(row.original.id, row, true)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => eliminarRegistro(row.original.id, row.original)}>
                    <Delete />
                  </IconButton>
                </>
              )}
            </Box>
          )}
        />
      </Box>

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        maxWidth="md"
        fullWidth
      >
        {/* Add your editing fields here using 'editingData' */}
        <EditarServicio
          rowdata={editingDataservicios}
          setEditingDataservicios={setEditingDataservicios}
          ListarServicios={ListarServicios}
          setIsModalOpen={setIsModalOpen}
          edit={mode}
        ></EditarServicio>
      </Dialog>
    </>
  );
}
