import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  LabelList,
} from "recharts";

import { useNavigate } from "react-router";

export default function Servicios() {
  const navigate = useNavigate();
  const user = localStorage.getItem("nombre-Completo");

  const NuevoServicio = () => {
    navigate("/NuevoServicio");
  };
  const ServiciosPendientes = () => {
    navigate("/Servicios-Pendientes");
  };
  const [data, setData] = useState([]);

  useEffect(() => {
    const offlinePosts = JSON.parse(localStorage.getItem("offlinePosts")) || [];
    const countByMonth = offlinePosts.reduce((acc, post) => {
      const month = new Date(post.fecha).getMonth();
      acc[month] = (acc[month] || 0) + 1;
      return acc;
    }, []);

    const chartData = Object.keys(countByMonth).map((month) => ({
      name: new Date().toLocaleString("default", {
        month: "long",
        timeZone: "UTC",
      }),
      "Servicios Pendientes": countByMonth[month],
    }));

    setData(chartData);
  }, []);

  const emojis = [
    '\\u{1F600}', '\\u{1F601}', '\\u{1F603}', '\\u{1F604}', '\\u{1F605}',
  ];

  function getRandomEmoji() {
    const randomIndex = Math.floor(Math.random() * emojis.length);
    const emojiCode = emojis[randomIndex].match(/\\u\{([0-9A-Fa-f]+)\}/)[1];
    return String.fromCodePoint(parseInt(emojiCode, 16));
  }

  const randomEmoji = getRandomEmoji();

  return (
    <>
      <Box
        id="ok"
        sx={{
          "& .MuiTextField-root": { m: 1 },
          boxShadow: 5,
          p: 4,
          m: 2,
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          color="white"
          align="center"
          pb={1}
          marginBottom={"20px"}
          style={{
            backgroundColor: "#161616",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          {`Bienvenido, ${user} ${randomEmoji} `}
        </Typography>
        <Typography
          style={{
            marginBottom: "20px",
          }}
        >
          Tienes los siguientes servicios pendientes de enviar, por favor ve a
          la pantalla de servicios pendientes y envíalos para que puedan ser
          procesados
        </Typography>
        <ResponsiveContainer width="50%" height={300}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="4 3" />
            <XAxis dataKey="name" />
            <YAxis domain={[0, "dataMax + 10"]}>
              <Label
                value="Servicios"
                angle={-90}
                position="insideLeft"
                offset={10}
              />
            </YAxis>
            <Tooltip />
            <Legend />
            <Bar dataKey="Servicios Pendientes" fill="#ED9519">
              {" "}
              <LabelList
                dataKey="Servicio Pendientes"
                position="top"
                content={(props) => `${props.value} Pendientes`}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={ServiciosPendientes}
            variant="contained"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              backgroundColor: '#ED9519', color: '#FFFFFF'
            }}
          >
            Ir a servicios pendientes de enviar
          </Button>
          <Button
            onClick={NuevoServicio}
            variant="contained"
            style={{backgroundColor: '#161616', color: '#FFFFFF', marginLeft: "15px"}}
          >
            Ir a crear un nuevo servicio
          </Button>
        </Box>
      </Box>
    </>
  );
}
