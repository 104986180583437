import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useParams } from "react-router";
import ViewImages from "../components/ViewImage/ViewImage";
import api from "../components/utils/api";
import { useNavigate } from "react-router";
import swal from "sweetalert";


export default function ServiciosImagenes() {
  const [loading, setLoading] = useState(false);
  const [servicios, setServicios] = useState([]);
  const userToken = localStorage.getItem("user-token");
  const { idservicio } = useParams();
  const UrlServicios = api.urlAPI + "servicios/" ;

  const ListarServicios = async () => {
    const url = `${UrlServicios}${idservicio}`
    try {
      const headers = { "iron-token": userToken };
      setLoading(true);
      let res = await fetch(url, { headers });
      setLoading(false);
      if (res.ok) {
        let data = await res.json();
        setServicios(data);
      } else {
        setLoading(false);
        throw new Error(
          "No fue posible obtener los registros, vuelve a intentar más tarde."
        );
      }
    } catch (error) {
      setLoading(false);
      swal("Error", error.message, "error");
    }
  };
  useEffect(() => {
    ListarServicios();
  }, []);
  const handleClick = () => {
    window.history.back();
  };
  return (
    <>
      <Box
        id="ok"
        sx={{
          "& .MuiTextField-root": { m: 1 },
          boxShadow: 5,
          p: 4,
          m: 2,
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          color="white"
          align="center"
          pb={1}
          marginBottom={"20px"}
          style={{
            backgroundColor: "#161616",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          {`Imagenes del servicio ${idservicio}`}
        </Typography>
          <ViewImages servicios = {servicios.data?.imagenesPorServicios}></ViewImages>
          <Button
          fullWidth
          variant="contained"
          onClick={handleClick}
          style={{
            backgroundColor: "#ED9519",
      
          }}
          >Volver</Button>
      </Box>
      
    </>
  );
}
