var api = {
    urlAPI: "https://app-service-iron-group.com.ar/APIapi/",
    urlImagenes: "https://app-service-iron-group.com.ar/APIImagenes/",
    crmOportunidades: "https://app-service-iron-group.com.ar/APIapi/crm/oportunidades",
    crmTurnos: "https://app-service-iron-group.com.ar/APIapi/crm/turnos",
    crmMaquinas:"https://app-service-iron-group.com.ar/APIapi/crm/maquinas",
crmClientes:"https://app-service-iron-group.com.ar/APIapi/crm/clientes/"

}

export default api