
export default function useFetch() {


 const fetchOportunidad = async (url, options) => {
  const request = new Request(url, options);
  try {
    const response = await fetch(request);
    const result = await response.json();
    return { responseOportunidad: result, errorOportunidad: null };
  } catch (error) {
    return { responseOportunidad: null, errorOportunidad: error };
  }
 }
 const fetchTurnos = async (url, options) => {
  const request = new Request(url, options);
  try {
    const response = await fetch(request);
    console.log(response)
    const result = await response.json();
    return { responseTurno: result, errorTurno: null };
  } catch (error) {
    return { responseTurno: null, errorTurno: error };
  }
 }
 const fetchMaquinas = async (url, options) => {
  const request = new Request(url, options);
  try {
    const response = await fetch(request);

    const result = await response.json();
    return { responseMaquina: result, errorMaquina: null };
  } catch (error) {
    return { responseMaquina: null, errorMaquina: error };
  }
 }
 const fetchCliente = async (url, options) => {
  const request = new Request(url, options);
  console.log(request)
  try {
    const response = await fetch(request);
    console.log(response)

    const result = await response.json();
    return { responseCliente: result, errorCliente: null };
  } catch (error) {
    return { responseCliente: null, errorCliente: error };
  }
 }
 const fetchFlag = async (url, options) => {
  const request = new Request(url, options);
  try {
    const response = await fetch(request);
    console.log(response)
    const result = await response.json();
    return { responseFlag: result, errorFlag: null };
  } catch (error) {
    return { responseFlag: null, errorFlag: error };
  }
 }
 
 return {fetchOportunidad, fetchTurnos, fetchMaquinas, fetchFlag, fetchCliente}
}

