import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography, Dialog } from "@mui/material";
import api from "../components/utils/api";
import { MaterialReactTable } from "material-react-table";
import IconButton from "@mui/material/IconButton";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { colums } from "../components/utils/Columns";
import swal from "sweetalert";
import EditarServicio from "../components/EditarServicio/EditarServicio";
import Loading from "../components/Loading/Loading";
import SendIcon from "@mui/icons-material/Send";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function ServiciosPendientes() {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const userToken = localStorage.getItem("user-token");
  const UrlServicios = api.urlAPI + "servicios";
  const [servicios, setServicios] = useState([]);
  const [images, setImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [editingDataservicios, setEditingDataservicios] = useState({});
  const Urlfile = api.urlAPI + "files/base";

  const [loading, setLoading] = useState(false);
  const rol = localStorage.getItem("roles");
  const mostrarBotones = rol === "ROLE_ADMIN";
  const navigate = useNavigate();

  const getOfflineData = () => {
    const offlineData = localStorage.getItem("offlinePosts") || [];
    if(offlineData.length ===0){
      return []
    }
    const JsonOfflineData = JSON.parse(offlineData);

    const bodyDataArray = [];

    for (let i = 0; i < JsonOfflineData.length; i++) {
      const bodyData = JSON.parse(JsonOfflineData[i].body);
      bodyDataArray.push(bodyData);
    }
    return bodyDataArray;
  };
  function eliminarRegistroPorIdInterno(idInterno) {
    const datosLocalStorage = localStorage.getItem("offlinePosts");
    if (datosLocalStorage) {
      console.log("local");
      const registros = JSON.parse(datosLocalStorage);
      const index = registros.findIndex((registro) =>
        registro.body.includes(`"idInterno":"${idInterno}"`)
      );
      if (index !== -1) {
        registros.splice(index, 1);
        localStorage.setItem("offlinePosts", JSON.stringify(registros));
      }
    }
  }

  function eliminarSeriviciosImagenesPorIdInterno(idInterno) {
    const registros = JSON.parse(localStorage.getItem("imagenesPorServicios")) || [];

    const registrosFiltrados = registros ? registros.filter(registro => registro.idInterno !== idInterno) : [];
    localStorage.setItem("imagenesPorServicios", JSON.stringify(registrosFiltrados));
    //
    const registro = JSON.parse(localStorage.getItem("images")) || [];
    const registrosFiltrado = registro ? registro.filter(registro => registro.idInterno !== idInterno) : [];
    localStorage.setItem("images", JSON.stringify(registrosFiltrado));
  }
  const ListarServicios = async () => {
    try {
      setLoading(true);
      // Obtener los datos de localStorage
      const data = getOfflineData();
      // Simular el tiempo de carga
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (data) {
        setServicios(data);
      } else {
        throw new Error(
          "No fue posible obtener los registros pendientes de enviar."
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      swal("Error", error.message, "error");
    }
  };

  const editarServicio = (id, row) => {
    setEditingData(row);
    setIsModalOpen(true);
    setEditingDataservicios(row.original);
  };
  const enviarFormulario = async (row) => {
    try {
      const confirmacion = await swal({
        title: "¿Estás seguro?",
        text: "Se enviará el documento, si fue enviado correctamente se eliminara, pero lo podrás visualizar en la pantalla servicios.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (!confirmacion) {
        return; // No se realiza ninguna acción adicional si el usuario cancela
      }
      if(!navigator.onLine){
        swal({
          title: "No tienes conexión, vuelve a intentarlo más tarde.",
          icon: "error",
          showConfirmButton: false,
          timer: 4000,
        });
        return
      }
      const imagedata = obtenerObjetosPorId2(row.original.idInterno)
      const reponseImage = await handleUpload(row);
      const body = {
        cliente: row.original.cliente,
        fecha: row.original.fecha,
        contacto: row.original.contacto,
        direccion: row.original.direccion,
        localidad: row.original.localidad,
        dni: row.original.dni,
        provincia: row.original.provincia,
        modelo: row.original.modelo,
        chasis: row.original.chasis,
        motor: row.original.motor,
        tipoServicioReparacion: row.original.tipoServicioReparacion,
        tipoServicioServicio: row.original.tipoServicioServicio,
        tipoServicioGarantia: row.original.tipoServicioGarantia,
        tipoServicioOtros: row.original.tipoServicioOtros,
        descripcionFalla: row.original.descripcionFalla,
        servicio: row.original.servicio,
        kmRecorridos: row.original.kmRecorridos,
        horaInicio: row.original.horaInicio,
        horaFin: row.original.horaFin,
        tecnico: row.original.tecnico,
        firmaTecnico: row.original.firmaTecnico,
        firmaCliente: row.original.firmaCliente,

        aclaracionCliente: row.original.aclaracionCliente,
        horometro: row.original.horometro,
        hodometro: row.original.hodometro,
        flagCrm: row.original.flagCrm,
        idInterno: row.original.idInterno,
        repuestosPorServicios: row.original.repuestosPorServicios,
        imagenesPorServicios: imagedata,
      };
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "iron-token": userToken,
        },
      };
      setLoading(true);
      console.log(body)
      const response = await fetch(UrlServicios, requestOptions);
      const data = await response.json();
      setLoading(false);
      swal({
        title: "Servicio Creado!",
        icon: "success",
        showConfirmButton: false,
        timer: 4000,
      });
      eliminarSeriviciosImagenesPorIdInterno(row.original.idInterno)
      eliminarRegistroPorIdInterno(row.original.idInterno);
      navigate("/Servicios");
    } catch (err) {
      console.log(err)
      swal({
        title: "Ocurrió un error, contactar con el administrador",
        icon: "error",
        showConfirmButton: false,
        timer: 4000,
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(() => colums, []);

  useEffect(() => {
    ListarServicios();
  }, []);

  const obtenerObjetosPorId = (idInterno) => {
    const offlineImages = JSON.parse(localStorage.getItem("images")) || [];
    const objetosFiltrados = offlineImages.filter(
      (objeto) => objeto.idInterno === idInterno
    );
    return objetosFiltrados;
  };
  const obtenerObjetosPorId2 = (idInterno) => {
    const offlineImages = JSON.parse(localStorage.getItem("imagenesPorServicios")) || [];
    const objetosFiltrados = offlineImages.filter(
      (objeto) => objeto.idInterno === idInterno
    );
    return objetosFiltrados;
  };

  const handleUpload = async (row) => {
    const imageObject = await obtenerObjetosPorId(row.original.idInterno);
    const responses = [];
    imageObject.forEach(async (obj) => {
      console.log(obj);
      const formData = { image: obj.image, name: obj.name };

      const requestOptions = {
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "application/json",
          "iron-token": userToken,
        },
      };

      try {
        const response = await axios.post(Urlfile, requestOptions.data, {
          headers: requestOptions.headers,
        });
        responses.push(response.data);
        setImages([...images, response.data]);
        console.log(images);
      } catch (error) {
        console.log("error", error);
        responses.push(error.response);
      }
    });
    console.log(responses);
    return responses;
  };

  return (
    <>
      <Box
        id="ok"
        sx={{
          "& .MuiTextField-root": { m: 1 },
          boxShadow: 5,
          p: 4,
          m: 2,
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          color="white"
          align="center"
          pb={1}
          marginBottom={"20px"}
          style={{
            backgroundColor: "#161616",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          Servicios Pendientes de enviar
        </Typography>

        <MaterialReactTable
          columns={columns}
          data={servicios}
          enableRowActions
          renderRowActions={({ row }) => (
            <Box>
              <IconButton onClick={() => editarServicio(row.original.id, row)}>
                <RemoveRedEyeIcon />
              </IconButton>
              {mostrarBotones && (
                <>
                  <IconButton onClick={() => enviarFormulario(row)}>
                    <SendIcon />
                  </IconButton>
                </>
              )}
            </Box>
          )}
        />
      </Box>
      {loading && <Loading />}
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        maxWidth="md"
        fullWidth
      >
        {/* Add your editing fields here using 'editingData' */}
        <EditarServicio
          rowdata={editingDataservicios}
          setEditingDataservicios={setEditingDataservicios}
          ListarServicios={ListarServicios}
          setIsModalOpen={setIsModalOpen}
          edit={false}
        ></EditarServicio>
      </Dialog>
    </>
  );
}
