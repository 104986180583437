import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import swal from "sweetalert";
import LogoutIcon from '@mui/icons-material/Logout';

export default function NavListDrawer({ navegationLinks, component, setOpen }) {

  const navigate = useNavigate();
    
  const logout = () => {
    swal({
      title: "Deseas cerrar sesion?",
      text: "Solo podras volver a ingresar si tienes conexion a internet",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmacion) => {
      if (confirmacion) {
        localStorage.removeItem("usuario");
        localStorage.removeItem("roles");
        localStorage.removeItem("user-token");
        localStorage.removeItem("firma");
        localStorage.removeItem("nombre-Completo");
        navigate("/auth/login");
      } else {
      }
    });
  };

  return (
    <Box sx={{ width: 250 }}>
      <nav>
        <List>
          {navegationLinks.map((item) => (
            <ListItem
              disablePadding
              key={item.title}
            >
              <ListItemButton
                component={component}
                to={item.path}
                onClick={() => setOpen(false)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText>{item.title}</ListItemText>
       
              </ListItemButton>
            </ListItem>
          ))}
           <ListItem
              disablePadding
              key={"LogOut"}
            >
              <ListItemButton
                component={component}
                onClick={() => logout()}
              >
                <ListItemIcon><LogoutIcon></LogoutIcon></ListItemIcon>
                <ListItemText>{"Cerrar Sesion"}</ListItemText>
       
              </ListItemButton>
            </ListItem>
        </List>
      </nav>
    </Box>
  );
}
