import React, { useState } from "react";
import { Button, Box, LinearProgress, TextField, Alert } from "@mui/material";
import axios from "axios";
import api from "../utils/api";
import Loading from "../Loading/Loading";

const checkInternetConnection = () => {
  return new Promise((resolve) => {
    const online = window.navigator.onLine;
    resolve(online);
  });
};

const FileUpload = ({
  setImgServicio,
  imgServicio,
  setLoadFile,
  idInterno,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState(false);
  const [message, setMessage] = useState({ msg: "", color: "" });
  const [isError, setIsError] = useState(false);
  const userToken = localStorage.getItem("user-token");
  const Urlfile = api.urlAPI + "files";
  const handleFileSelect = (event) => {
    setMessage({ msg: "", color: "" });
    setSelectedFiles([...event.target.files]);
    if (event.target.files.length === 0) {
      setLoadFile(false);
    } else {
      setLoadFile(true);
    }
  };

  const handleUpload = async () => {
    setProgress(true);
    const online = await checkInternetConnection();

    if (online) {
      // Si hay conexión a internet, realiza la solicitud POST
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("images", file);
      });

      const requestOptions = {
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "iron-token": userToken,
        },
      };

      try {
        const response = await axios.post(Urlfile, requestOptions.data, {
          headers: requestOptions.headers,
        });
        setMessage({ msg: "Archivo subido correctamente", color: "success" });
        setProgress(false);
        setImgServicio(response.data?.files);
      } catch (error) {
        setMessage({ msg: "No pudimos subir tu archivo", color: "error" });
        setProgress(false);
      }
    } else {
      //Offline guardo las imagenes en cache
      selectedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onload = function (event) {
          const offlineImages =
            JSON.parse(localStorage.getItem("images")) || [];
          const offlineImagesServicios =
            JSON.parse(localStorage.getItem("imagenesPorServicios")) || [];
          const base64ImageObject = {
            image: event.target.result,
            idInterno: idInterno,
            name: Date.now() + "-" + idInterno,
          };
          const extension = event.target.result.substring(
            "data:image/".length,
            event.target.result.indexOf(";base64")
          );
          const imagenesPorServicios = {
            fieldname: "images",
            originalname: `${base64ImageObject.name}.${extension}`,
            encoding: "7bit",
            mimetype: "image/png",
            destination: "./uploads",
            filename: `${base64ImageObject.name}.${extension}`,
            path: `uploads\\${base64ImageObject.name}.${extension}`,
            size: 135682,
            idInterno: idInterno,
          };
          offlineImages.push(base64ImageObject);
          offlineImagesServicios.push(imagenesPorServicios);
          localStorage.setItem("images", JSON.stringify(offlineImages));
          localStorage.setItem(
            "imagenesPorServicios",
            JSON.stringify(offlineImagesServicios)
          );
        };

        reader.readAsDataURL(file);
      });
      setMessage({ msg: "Sin conexion - Archivo guardado Localmente", color: "success" });
    }
    
    setSelectedFiles([]);
    setLoadFile(false);
    setProgress(false);

  };

  return (
    <div>
      <TextField
        type="file"
        fullWidth
        onChange={handleFileSelect}
        inputProps={{
          multiple: true,
        }}
      />

      <div className="file-names">
        {selectedFiles &&
          selectedFiles.map((file, index) => (
            <div key={index}>{file.name}</div>
          ))}
      </div>

      <>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            color="success"
            fullWidth
            onClick={handleUpload}
            style={{ marginTop: "10px" }}
            disabled= {!selectedFiles.length>0}
          >
            Cargar archivos
          </Button>
        </Box>
        <Box sx={{ width: "100%", marginTop: "20px" }}>
          {progress && <Loading></Loading>}
          {message.color !== "" && (
            <Alert variant="filled" severity={message.color}>
              {message.msg}
            </Alert>
          )}
        </Box>
      </>
    </div>
  );
};

export default FileUpload;
