export const stylesPdf = {
  header: {
    fontSize: 18,
    bold: true,
    margin: [0, 0, 0, 10],
  },
  subheader: {
    fontSize: 12,
    bold: true,
    margin: [0, 10, 0, 5],
    alignment: "justify",
    backgroundColor: "red",
  },
  subheader2: {
    fontSize: 13,
    bold: true,
    margin: [0, 30, 0, 0],
  },
  tableExample: {
    fontSize: 10,
    margin: [0, 5, 0, 5],
  },
  tableExample2: {
    fontSize: 8,
    margin: [0, 5, 0, 15],
  },
  direccion: {
    fontSize: 10,
  },
  imagenLogo: {
    fontSize: 9,
    margin: [15, 0, 0, 0],
  },
  tableHeader: {
    bold: true,
    fontSize: 10,
    color: "black",
  },
  fecha: {
    fontSize: 10,
    bold: true,
    margin: [0, 32, 0, 5],
    backgroundColor: "red",
  },
};
